import types from '../mutation-types';
import { FEATURE_FLAGS } from '../../featureFlags';
import KanbanAPI from '../../api/kanban';

const state = {
  isEnabled: false,
};

const getters = {
  isKanbanEnabled: (_state) => _state.isEnabled,
};

const actions = {
  setKanbanEnabled({ commit }, isEnabled) {
    commit(types.SET_KANBAN_ENABLED, isEnabled);
  },
  async updateItemStructure({ commit }, { itemId, structure }) {
    try {
      const response = await KanbanAPI.updateItemStructure(itemId, structure);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async saveKanbanItemData({ commit }, { itemId, itemData }) {
    try {
      const response = await KanbanAPI.updateItemDetails(itemId, itemData);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async createKanbanItem({ commit }, { conversationId, itemData }) {
    try {
      const response = await KanbanAPI.createItem(conversationId, itemData);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  [types.SET_KANBAN_ENABLED](_state, isEnabled) {
    _state.isEnabled = isEnabled;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
