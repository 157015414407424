/* global axios */
import ApiClient from './ApiClient';
import store from '../store';

class KanbanAPI extends ApiClient {
  constructor() {
    super('kanban', { accountScoped: true });
  }

  get accountId() {
    return store.state.auth.currentUser.account_id;
  }

  fetchColumns() {
    return axios.get(`${this.url}/columns`);
  }

  fetchItems() {
    return axios.get(`${this.url}/items`);
  }

  createItem(conversationId, itemData) {
    return axios.post(`/api/v1/accounts/${this.accountId}/kanban/items`, {
      conversation_id: conversationId,
      funnel_id: itemData.funnel_id,
      funnel_stage: itemData.funnel_stage
    });
  }

  updateItemStructure(itemId, structure) {
    return axios.patch(`/api/v1/accounts/${this.accountId}/kanban/items/${itemId}/update_structure`, {
      item_structure: structure
    });
  }

  updateItemDetails(itemId, details) {
    return axios.patch(`/api/v1/accounts/${this.accountId}/kanban/items/${itemId}/update_details`, {
      item_details: details
    });
  }
}

export default new KanbanAPI();
